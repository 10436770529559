import React from "react";
import { graphql, Link } from "gatsby";
//import Img from "gatsby-image";
import Imgix from "react-imgix";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const BlogTemp = ({ data, location, pageContext }) => (
    <Layout>
        <Seo
            pagetitle="ブログ"
            pagedesc="ESSENTIALSのブログです"
            pagepath={location.pathname}
        ></Seo>
        <section className="content bloglist">
            <div className="container">
                <h1 className="bar">RECENT POSTS</h1>
                <div className="posts">
                    {data.allMicrocmsBlog.edges.map( ({ node }) => (
                        <article className="post" key={node.id}>
                            <Link to={`/blog/post/${node.slug}/`}>
                                <figure>
                                    <Imgix src={node.eyecatch.url} sizes="(max-width: 500px) 100vw, 500px" htmlAttributes={{ alt: "" }}></Imgix>
                                </figure>
                                <h3>{node.title}</h3>
                            </Link>
                        </article>
                    )  )}
                </div>
                <ul className="pagenation">
                    { !pageContext.isFirst && (
                        <li className="prev">
                            <Link to={pageContext.currentPage === 2 ? `/blog/` : `/blog/${pageContext.currentPage - 1}/` } rel="prev">
                                <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
                                <span>前のページ</span>
                            </Link>
                        </li>
                    )}
                    { !pageContext.isLast && (
                    <li className="next">
                        <Link to={`/blog/${pageContext.currentPage + 1}/`} rel="next">
                            <span>次のページ</span>
                            <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                        </Link>
                    </li>
                    )}
                </ul>
            </div>
        </section>
    </Layout>
);
export default BlogTemp;

export const query = () => graphql`
    query ( $skip: Int!, $limit: Int! ) {
      allMicrocmsBlog(
        sort: {order: DESC, fields: publishDate}, 
        skip: $skip, 
        limit: $limit
      ) {
        edges {
          node {
            title
            id
            slug
            eyecatch {
              url
            }
          }
        }
      }
    }
`
